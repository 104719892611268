<template>
    <div class="container md-layout md-alignment-top-center">
        <div class="md-layout-item md-size-50 md-small-size-80 md-xsmall-size-90">
            <md-card>
                <md-card-header>
                    <div class="md-title">
                        Aktivierung
                    </div>
                </md-card-header>

                <md-card-content v-if="mode === MODES.NOINPUT">
                    In dem Link, den Du verwendest fehlt der Bestätigungscode oder Deine Mailadresse.
                </md-card-content>
                <md-card-content v-if="mode === MODES.WAITING">
                    Die Aktivierung wird durchgeführt.
                </md-card-content>
                <md-card-content v-if="mode === MODES.SUCCESS">
                    Die Aktivierung war erfolgreich. Du kannst dich nun anmelden.
                </md-card-content>
                <md-card-content v-if="mode === MODES.FAILING">
                    Es ist ein Fehler aufgetreten.
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
const MODES= {
    NOINPUT: 0,
    WAITING: 1,
    SUCCESS: 2,
    FAILING: 9
}

export default {
    name: 'All_PasswordReset',
    data: () => ({
        mode: MODES.NOINPUT,
        
        MODES,
    }),
    mounted() {
        const t = this;
        const key_enc = this.$route.params.key
        const mail_enc = this.$route.params.mail
        let key = undefined
        let mail = undefined
        if(key_enc) {
            key = decodeURIComponent(key_enc)
        }
        if(key_enc) {
            mail = decodeURIComponent(mail_enc)
        }
        if (key && key !== "" && mail && mail !== "") {
            t.mode = MODES.WAITING
            t.$store.dispatch("verification", {key: key_enc, mail: mail_enc}).then( () => {
                t.mode = MODES.SUCCESS
            }).catch( () => {
                t.mode = MODES.FAILING
            })
        }
    },
    computed: {
        sending: function () {
            return this.$store.state.rxtx > 0
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
#container {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
}
</style>
